import request from '@index/utils/request'

export function handleLoadMatchs(data) {
  return request({
    url: 'matchinfo/pageMatchs',
    method: 'post',
    data
  })
}


export function handleDetails(id) { //比赛详情
  return request({
    url: 'matchinfo/details',
    method: 'post',
    params: {
      id: id
    }
  })
}

export function handleStreamlineDetails(id) { //比赛详情
  return request({
    url: 'matchinfo/streamlineDetails',
    method: 'post',
    params: {
      id: id
    }
  })
}
